export default [
  {
    id: '1',
    title: '规划集成',
    descript: ['物流中心规划', '智能装备集成', '物流网络规划', '运营诊断分析'],
    image: '1',
    detail: [
      {
        title: '物流中心规划',
        show: true,
        graph: {
          image: '13',
          imageWidth: 6.81,
          imageHeight: 3.67,
          descript: ''
        },
        feature: [
          {
            title: '',
            descript: [
              '经营模式/服务定位分析论证',
              '规模/需求/投资预算设计论证',
              '运营数据分析/作业流程设计',
              '功能布局、动线设计与仿真'
            ]
          }
        ]
      },
      {
        title: '智能设备集成',
        show: false,
        graph: {
          image: '14',
          imageWidth: 7.08,
          imageHeight: 3.97,
          descript: ''
        },
        feature: [
          {
            title: '',
            descript: [
              '场景分析与自动化集成规划',
              '定制化设备设计研发与制造',
              '工程验收服务、维保服务',
              '采购咨询服务、施工监理'
            ]
          }
        ]
      },
      {
        title: '网格规划布局',
        show: false,
        graph: {
          image: '15',
          imageWidth: 6.75,
          imageHeight: 4.82,
          descript: ''
        },
        feature: [
          {
            title: '',
            descript: [
              '物流网络结构与运作模式规划',
              '网络选中、场地规划、路由规划设计',
              '干支线网、终端线路及多主体协同模式设计',
              '组织架构、管理流程、作业流程设计',
              '网络运作过程监管与主动风险防范机制设计'
            ]
          }
        ]
      },
      {
        title: '运营管理咨询',
        show: false,
        graph: {
          image: '16',
          imageWidth: 6.98,
          imageHeight: 4.17,
          descript: ''
        },
        feature: [
          {
            title: '',
            descript: [
              '物流项目上线运营辅导',
              '运营质量与作业效益分析诊断',
              '作业流程、作业方式诊断优化',
              '绩效管理优化/运作管理优化',
              '管理与操作人员培训、培养'
            ]
          }
        ]
      }
    ]
  },
  {
    id: '2',
    title: '信息系统',
    descript: ['物流应用系统矩阵构建', '业务与IT流程融合再造', '企业信息基础设施建设'],
    image: '2',
    detail: [
      {
        title: '物流应用系统矩阵构建',
        show: true,
        graph: {
          image: '10',
          imageWidth: 6.88,
          imageHeight: 4.06,
          descript: '全维度产品线，实现物流全链路应用系统覆盖，全过程数字化管理'
        },
        feature: [
          {
            title: '业务管理',
            descript: 'ELP物流门户、OMS订单管理'
          },
          {
            title: '运营管理',
            descript: 'BMS计费管理、OAS运营分析'
          },
          {
            title: '运作管理',
            descript: 'WMS仓储管理 ,TMS运配管理，WCS设备控制'
          }
        ]
      },
      {
        title: '业务与IT流程融合再造',
        show: false,
        graph: {
          image: '11',
          imageWidth: 6.78,
          imageHeight: 3.66,
          descript: '基于数据，数字物流管理模型进行分析诊断，实现流程与IT管控融合'
        },
        feature: [
          {
            title: '分析诊断',
            descript: '系统数据汇集，统计分析与问题诊断'
          },
          {
            title: '流程开发',
            descript: '物流的运营、作业流程的梳理、改造与调优'
          },
          {
            title: '运行管控',
            descript: 'BMP流程工具开发流程与实施，执行管控'
          }
        ]
      },
      {
        title: '企业数字化基础设施',
        show: false,
        graph: {
          image: '12',
          imageWidth: 5.41,
          imageHeight: 4.15,
          descript: '基于敏捷研发工具与集成工具，业务与数据的双中台架构，实现企业多系统的融合应用'
        },
        feature: [
          {
            title: '企业信息集成',
            descript: '应用MIP集成平台工具，无缝集成各分子系统的数据、服务与应用，快速满足业务'
          },
          {
            title: '技术架构治理',
            descript: '互联网/物联网/大数据架构升级转型，服务与应用中心化治理，业务与数据双中台建设'
          },
          {
            title: '敏捷开发体系',
            descript: 'TDP技术开发平台与DDP数据开发平台，全WEB研发与管理环境，提升效率70%以上'
          }
        ]
      }
    ]
  },
  {
    id: '3',
    title: '智能装备',
    descript: ['穿梭车零货自动拣选系统', '穿梭车整件自动拣选系统', '柔性机器人自动拣选系统'],
    image: '3',
    detail: [
      {
        title: '穿梭车系列产品',
        detailType: 2,
        show: true,
        graph: {
          image: '',
          imageWidth: 0,
          imageHeight: 0,
          descript: ''
        },
        feature: [
          {
            title: '高速式穿梭车',
            image: '17',
            imageWidth: '3.8',
            imageHeight: '2.8'
          },
          {
            title: '全向式穿梭车',
            image: '22',
            imageWidth: '3.8',
            imageHeight: '2.8'
          },
          {
            title: '变量式穿梭车',
            image: '25',
            imageWidth: '3.8',
            imageHeight: '2.8'
          },
          {
            title: '重载式穿梭车',
            image: '23',
            imageWidth: '3.8',
            imageHeight: '2.8'
          }
        ]
      },
      {
        title: '搬运机器人系列产品',
        detailType: 2,
        show: true,
        graph: {
          image: '',
          imageWidth: 0,
          imageHeight: 0,
          descript: ''
        },
        feature: [
          {
            title: '潜伏机器人',
            image: '18',
            imageWidth: '3.54',
            imageHeight: '3.08'
          },
          {
            title: '移载机器人',
            image: '26',
            imageWidth: '3.54',
            imageHeight: '3.08'
          },
          {
            title: '料箱拣选机器人',
            image: '24',
            imageWidth: '3.54',
            imageHeight: '3.08'
          }
        ]
      },
      {
        title: '穿梭车零货自动拣选方案',
        show: true,
        graph: {
          image: '19',
          imageWidth: 7.07,
          imageHeight: 4.77,
          descript: ''
        },
        feature: [
          {
            title: '',
            descript: [
              '支持货物的多深位密集存储和处理，相比传统零货处理效率提升5~10倍',
              '箱式穿梭车、提升机和货到人拣选系统对接，全自动出入库，快速的拆零拣选作业',
              '穿梭车支持双向/四向行走，系统可根据业务需求柔性灵活扩展配置'
            ]
          }
        ]
      },
      {
        title: '穿梭车整件自动拣选方案',
        show: false,
        graph: {
          image: '20',
          imageWidth: 7.07,
          imageHeight: 4.77,
          descript: ''
        },
        feature: [
          {
            title: '',
            descript: [
              '高密度存储，较同等立体库相比，存储密度提升20%~30%',
              '托盘式四向穿梭车、提升机和桁架机械手拣选系统对接，全自动出入库，实现在线拣选功能',
              '可根据效率需求调整穿梭车数量，提升存储能力和吞吐流量，灵活适应各种复杂的仓库结构'
            ]
          }
        ]
      },
      {
        title: '搬运机器人自动拣选方案',
        show: false,
        graph: {
          image: '21',
          imageWidth: 7.08,
          imageHeight: 5.05,
          descript: ''
        },
        feature: [
          {
            title: '',
            descript: [
              '可节省30%~50%人工，达到人工拣选2~3倍的工作效率',
              'AI算法，实现机器人调度控制的最优路径，最少交叉与最短等待',
              '多类型搬运机器人与拣选工作站自由组合，可柔性增减机器人数量，满足不同业务量的需求'
            ]
          }
        ]
      }
    ]
  }
]