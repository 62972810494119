<!-- 产品与服务详情页模板 -->
<template>
  <div class="mobile-container mobile-product-service-detail" v-title="title">
    <div class="header">
      <mobile-header></mobile-header>
    </div>
    <div class="body">
      <div class="item" v-for="(item, index) in detail" :key="index">
        <div class="item-title">
          <span>{{ item.title }}</span>
        </div>
        <div class="item-body" :class="{'no-border': item.detailType}">
          <div class="graph" v-if="!item.detailType">
            <img :src="require(`@/assets/image/mobile/photo-${item.graph.image}.png`)" :style="`width: ${item.graph.imageWidth}rem; height: ${item.graph.imageHeight}rem;`">
            <div class="description">{{ item.graph.descript }}</div>
          </div>
          <div class="slide-list" v-if="item.detailType">
            <div class="slide-list-wrapper" :style="`width: ${item.feature.length * (6.6 + 0.15)}rem;`">
              <div class="slide-item" v-for="(f, i) in item.feature" :key="`${index + 1}_${i + 1}`">
                <div class="slide-img">
                  <img :src="require(`@/assets/image/mobile/photo-${f.image}.png`)" :style="`width: ${f.imageWidth}rem; height: ${f.imageHeight}rem;`">
                </div>
                <div class="slide-title">{{ `${f.title}` }}</div>
              </div>
            </div>
          </div>
          <div class="feature" v-else>
            <div class="feature-item" v-for="(f, i) in item.feature" :key="`${index + 1}_${i + 1}`">
              <div class="feature-title" v-if="f.title">{{ `${f.title}` }}</div>
              <div class="feature-description" v-if="typeof f.descript === 'string'">{{ f.descript }}</div>
              <div class="feature-description desc-list" :class="{'no-title': !f.title}" v-else v-for="(d, j) in f.descript" :key="`${index + 1}_${i + 1}_${j + 1}`">{{ d }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 返回主页 -->
    <div class="mobile-back" @click="back">
      <img src="@/assets/image/mobile/icon-33.png">
    </div>
  </div>
</template>

<script>
  // 头部
  import MobileHeader from '@/components/mobile/mobile-header/mobile-header'
  import productServiceData from './product-service-data'

  export default {
    name: 'MobileProductServiceDetail',
    data () {
      return {
        title: '',
        detail: []
      }
    },
    components: {
      MobileHeader
    },
    methods: {
      // 返回
      back () {
        this.$router.push({
          path: '/mobileProductService'
        })
      },
      // 初始化
      init () {
        let id = this.$route.params.id
        let arr = productServiceData.filter(item => item.id === id)
        if (arr.length > 0) {
          this.title = arr[0].title
          this.detail = arr[0].detail
        }
      }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style lang="scss">
  @import '@/components/mobile/style/common.scss';

  .mobile-container.mobile-product-service-detail {
    .body {
      background-color: $white-color;
      padding: 1.28rem $distance-1 0;
      .item {
        font-family: Source Han Sans CN;
        font-size: $font-size-3;
        color: $text-color-1;
        font-weight: 400;
        margin-bottom: 0.5rem;
        .item-title {
          text-align: center;
          position: relative;
          font-size: $font-size-8;
          font-weight: 500;
        }
        .item-body {
          border-width: 1px;
          border-style: solid;
          border-color: $border-color-2;
          // box-shadow: 0px 0px 4px 0px rgba(102, 102, 102, 0.35);
          padding-top: $distance-1;
          margin-top: $distance-2;
          overflow: hidden;
          .graph {
            img {
              display: block;
              margin: 0 auto $distance-2;
            }
            .description {
              margin: 0 $distance-2;
              padding-bottom: 0.4rem;
              line-height: 0.46rem;
              border-bottom: 1px solid $border-color-2;
            }
          }
          .feature {
            padding: 0.5rem $distance-2;
            .feature-item {
              margin-bottom: 0.4rem;
              .feature-title {
                font-weight: 500;
              }
              .feature-description {
                margin-top: 0.14rem;
                font-size: $font-size-2;
                color: $text-color-3;
                line-height: 0.36rem;
                position: relative;
                &.desc-list {
                  padding-left: 0.26rem;
                }
                &.desc-list:before {
                  content: '';
                  width: 0.08rem;
                  height: 0.08rem;
                  border-radius: 0.04rem;
                  background-color: $text-color-3;
                  position: absolute;
                  left: 0;
                  top: 0.16rem;
                }
                &.no-title:nth-of-type(1) {
                  margin-top: 0;
                }
              }
              &:nth-last-of-type(1) {
                margin-bottom: 0;
              }
            }
          }
          .slide-list {
            overflow: auto;
            padding-bottom: 0.15rem;
            .slide-list-wrapper {
              position: relative;
              height: 4.16rem;
              .slide-shade {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 99;
              }
            }
            .slide-item {
              float: left;
              width: 6.6rem;
              height: 4.16rem;
              border: 1px solid $border-color-2;
              border-radius: 0.04rem;
              display: flex;
              flex-direction: column;
              overflow: hidden;
              margin-left: 0.15rem;
              .slide-img {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .slide-title {
                height: 0.88rem;
                line-height: 0.88rem;
                background-color: $bg-color-3;
                padding: 0 $distance-2;
              }
            }
          }
          &.no-border {
            border: none;
            height: 4.36rem;
            overflow: hidden;
          }
        }
      }
    }
  }
</style>